<template>
  <v-snackbar
    v-model="showBanner"
    v-if="isLoggedIn"
    :timeout="-1"
    :value="showBanner"
    right
    bottom
    class="cookie-consent"
    dark
    role="dialog"
    aria-label="Cookie Consent Settings"
    style="padding-bottom: 0px !important;"
  >
    <v-card 
      flat 
      dark 
      class="consent-card"
      tabindex="0"
      :aria-busy="isSaving"
      :ripple="false"
    >
      <!-- Header -->
      <v-card-title class="px-4 pt-4">
        <span class="text-h6">Cookies on this site</span>
        <!-- <v-btn
          icon
          small
          class="ml-auto"
          @click="handleClose"
          aria-label="Close cookie settings"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn> -->
      </v-card-title>
  
      <!-- Main Content -->
      <v-card-text class="px-4 pb-3">
        <!-- Initial Description - Always Visible -->
        <p class="body-2 grey--text text--lighten-1 mb-4">
          We use cookies to enhance and personalise your experience. Accept all
          cookies below, or select "Manage Cookies" to view our Privacy Policy and
          choose which cookies we can use. Settings can be changed later by
          selecting the "Cookie Settings" button in the footer of every page.
        </p>
  
        <!-- Detailed Cookie Settings -->
        <v-expand-transition>
          <div v-show="showDetails">
            <!-- Cookie Types -->
            <div class="cookie-settings mb-4">
              <!-- Essential Cookies -->
              <div class="cookie-section necessary mb-4">
                <div class="d-flex align-center mb-2">
                  <div class="flex-grow-1">
                    <div class="subtitle-1 font-weight-medium d-flex align-center">
                      <span id="necessary-cookies-label">Strictly Necessary Cookies</span>
                      <v-chip x-small label color="grey darken-3" class="ml-2">
                        Always Active
                      </v-chip>
                    </div>
                  </div>
                  <v-switch
                    v-model="preferences.necessary"
                    disabled
                    :input-value="true"
                    color="primary"
                    hide-details
                    dense
                    class="mt-0"
                    aria-labelledby="necessary-cookies-label"
                  ></v-switch>
                </div>
                <p class="body-2 grey--text text--lighten-1 mb-0">
                  These cookies are essential for the website to function and cannot be disabled.
                  They are usually set in response to actions you take such as logging in
                  or filling in forms.
                </p>
              </div>
  
              <!-- Analytics Cookies -->
              <div
                class="cookie-section clickable mb-4"
                role="button"
                tabindex="0"
                @click="togglePreference('analytics')"
                @keypress.enter="togglePreference('analytics')"
                :aria-pressed="preferences.analytics"
              >
                <div class="d-flex align-center mb-2">
                  <div class="flex-grow-1">
                    <div class="subtitle-1 font-weight-medium">
                      <span id="analytics-cookies-label">Analytics Cookies</span>
                    </div>
                  </div>
                  <v-switch
                    v-model="preferences.analytics"
                    color="primary"
                    hide-details
                    dense
                    class="mt-0"
                    @click.stop
                    aria-labelledby="analytics-cookies-label"
                  ></v-switch>
                </div>
                <p class="body-2 grey--text text--lighten-1 mb-0">
                  These cookies allow us to count visits and traffic sources so we
                  can measure and improve the performance of our site. They help us
                  understand which pages are the most and least popular.
                </p>
              </div>
  
              <!-- Policy Links -->
              <div class="d-flex mt-6 mb-4">
                <a
                  href="/#/dashboard/privacy-policy"
                  class="text-decoration-none caption mr-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Privacy Policy (opens in new tab)"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </v-expand-transition>
  
        <!-- Action Buttons -->
        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            :loading="isSaving"
            :disabled="isSaving"
            @click.stop="handleSaveAndExit"
            :aria-label="showDetails ? 'Save preferences and close' : 'Manage cookie settings'"
          >
            {{ showDetails ? "Save & Exit" : "Manage Cookies" }}
          </v-btn>
          <v-btn
            color="primary"
            small
            :loading="isSaving"
            :disabled="isSaving"
            @click="acceptNecessaryOnly"
            aria-label="Accept only necessary cookies"
          >
            Accept Only Necessary
          </v-btn>
          <v-btn
            color="primary"
            small
            :loading="isSaving"
            :disabled="isSaving"
            @click="acceptAll"
            aria-label="Accept all cookies"
          >
            Allow All
          </v-btn>
        </v-card-actions>
      </v-card-text>
  
      <!-- Loading Overlay -->
      <v-overlay
        :value="isSaving"
        absolute
        opacity="0.8"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          aria-label="Saving preferences"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-snackbar>
  </template>
  
  <script>
import UserController from '@/services/controllers/User';

const CONSENT_VERSION = '1.0';
const ESSENTIAL_COOKIES = ['session_id', 'csrf_token', 'necessary_cookie'];
const LOCAL_STORAGE_KEY = 'cookieConsent';
const getUserStorageKey = (userId) => `cookieConsent_${userId}`;

export default {
name: 'CookieConsent',

data: () => ({
  showBanner: false,
  showDetails: false,
  preferences: {
    necessary: true,
    analytics: false
  },
  originalPreferences: null,
  isSaving: false,
  initialized: false
}),

computed: {
  isLoggedIn() {
    return !!this.$store?.state?.User?.user?.id;
  },
  userId() {
    return this.$store?.state?.User?.user?.id;
  },
  hasChanges() {
    return JSON.stringify(this.preferences) !== JSON.stringify(this.originalPreferences);
  }
},

watch: {
  isLoggedIn: {
    immediate: true,
    async handler(newValue) {
      // console.log('Login state changed:', newValue);
      if (newValue && !this.initialized) {
        await this.initializeConsent();
        this.initialized = true;
      } else if (!newValue) {
        this.resetState();
      }
    }
  },
  userId: {
    immediate: false,
    handler(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        // console.log('User ID changed, reinitializing consent');
        this.initialized = false;
        this.initializeConsent();
      }
    }
  }
},

created() {
  // console.log('Cookie consent component created');
  this.$root.$on('show-cookie-consent', this.showCookieSettings);
  window.addEventListener('storage', this.handleStorageChange);
},

mounted() {
  // Clean up any old consent data format
  this.cleanupOldConsentData();
},

beforeDestroy() {
  this.$root.$off('show-cookie-consent', this.showCookieSettings);
  window.removeEventListener('storage', this.handleStorageChange);
},

methods: {
  handleStorageChange(event) {
    if (event.key === LOCAL_STORAGE_KEY || 
        (this.userId && event.key === getUserStorageKey(this.userId))) {
      // console.log('Cookie consent storage changed');
      const newData = this.getLocalConsentData();
      if (newData && this.validateConsentData(newData).isValid) {
        this.applyConsentData(newData);
      }
    }
  },

  getLocalConsentData() {
    try {
      // First try to get user-specific consent if logged in
      if (this.userId) {
        const userStorageKey = getUserStorageKey(this.userId);
        const userConsent = localStorage.getItem(userStorageKey);
        
        if (userConsent) {
          const parsedData = JSON.parse(userConsent);
          if (parsedData.userId === this.userId) {
            // console.log('Found valid user-specific consent data');
            return parsedData;
          }
        }
      }

      // Fall back to browser-level consent data
      const browserConsent = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (browserConsent) {
        const parsedData = JSON.parse(browserConsent);
        // console.log('Found browser-level consent data');
        return {
          ...parsedData,
          userId: this.userId || 'browser'
        };
      }
    } catch (error) {
      console.warn('Failed to get local consent data:', error);
    }
    return null;
  },

  createConsentData(preferences, timestamp = new Date().toISOString()) {
    return {
      preferences: {
        necessary: !!preferences.necessary,
        analytics: !!preferences.analytics
      },
      timestamp,
      version: CONSENT_VERSION,
      userId: this.userId || 'browser'
    };
  },

  async initializeConsent() {
    // console.log('Initializing consent...');
    try {
      let apiData = null;
      let localData = null;

      // First try to get API data if logged in
      if (this.isLoggedIn) {
        try {
          const response = await UserController.getUserSetting('UserConsent');
          if (response?.data?.value) {
            apiData = JSON.parse(response.data.value);
            // console.log('Found API consent data:', apiData);
          }
        } catch (error) {
          console.warn('Failed to fetch API consent data:', error);
        }
      }

      // Then get local storage data
      localData = this.getLocalConsentData();
      // console.log('Found local consent data:', localData);

      // Validate both data sources
      const apiValidation = this.validateConsentData(apiData);
      const localValidation = this.validateConsentData(localData);

      // console.log('Validation results:', {
      //   api: apiValidation,
      //   local: localValidation
      // });

      // Handle synchronization based on validation results
      if (apiValidation.isValid && localValidation.isValid) {
        await this.syncConsentData(apiData, localData);
        return;
      }

      // Handle single valid data source
      if (apiValidation.isValid) {
        // console.log('Using valid API data only');
        await this.syncToLocal(apiData);
        return;
      }

      if (localValidation.isValid) {
        // console.log('Using valid local data only');
        await this.syncToAPI(localData);
        return;
      }

      // No valid data found, show banner
      // console.log('No valid consent data found, showing banner');
      this.showBanner = true;

    } catch (error) {
      console.error('Consent initialization error:', error);
      this.showBanner = true;
    }
  },

  validateConsentData(data) {
    const result = {
      isValid: false,
      timestamp: null,
      error: null
    };

    try {
      if (!data) {
        result.error = 'No data provided';
        return result;
      }

      // Only check userId match if logged in
      if (this.userId && data.userId !== this.userId && data.userId !== 'browser') {
        result.error = 'User ID mismatch';
        // console.log('User ID mismatch:', { expected: this.userId, got: data.userId });
        return result;
      }

      if (!data.preferences) {
        result.error = 'Missing preferences';
        return result;
      }

      if (!data.timestamp) {
        result.error = 'Missing timestamp';
        return result;
      }

      if (data.version !== CONSENT_VERSION) {
        result.error = `Version mismatch. Expected ${CONSENT_VERSION}, got ${data.version}`;
        return result;
      }

      // Validate timestamp
      const consentDate = new Date(data.timestamp);
      if (isNaN(consentDate.getTime())) {
        result.error = 'Invalid timestamp format';
        return result;
      }

      // Check if consent is expired (6 months)
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
      
      if (consentDate <= sixMonthsAgo) {
        result.error = 'Consent expired';
        return result;
      }

      // Validate preferences structure
      if (typeof data.preferences.necessary !== 'boolean') {
        result.error = 'Invalid necessary preference type';
        return result;
      }

      if (typeof data.preferences.analytics !== 'boolean') {
        result.error = 'Invalid analytics preference type';
        return result;
      }

      result.isValid = true;
      result.timestamp = consentDate;
      
    } catch (error) {
      result.error = error.message;
      console.error('Validation error:', error);
    }

    return result;
  },

  async syncConsentData(apiData, localData) {
    try {
      const apiTimestamp = new Date(apiData.timestamp);
      const localTimestamp = new Date(localData.timestamp);
      
      // console.log('Syncing consent data:', {
      //   apiTimestamp,
      //   localTimestamp
      // });

      if (apiTimestamp > localTimestamp) {
        // console.log('API data is more recent, syncing to local');
        await this.syncToLocal(apiData);
      } else if (localTimestamp > apiTimestamp) {
        // console.log('Local data is more recent, syncing to API');
        await this.syncToAPI(localData);
      } else {
        // Timestamps are equal, just apply the data
        // console.log('Timestamps are equal, using existing data');
        this.applyConsentData(apiData);
      }
    } catch (error) {
      console.error('Error syncing consent data:', error);
      throw error;
    }
  },

  async syncToLocal(apiData) {
    try {
      const consentData = this.createConsentData(apiData.preferences, apiData.timestamp);
      await this.setLocalConsentData(consentData);
      this.applyConsentData(consentData);
    } catch (error) {
      console.error('Error syncing to local:', error);
      throw error;
    }
  },

  async syncToAPI(localData) {
    if (!this.isLoggedIn) {
      // console.log('Not logged in, skipping API sync');
      this.applyConsentData(localData);
      return;
    }

    try {
      await this.saveToAPI(localData);
      this.applyConsentData(localData);
    } catch (error) {
      console.error('Error syncing to API:', error);
      throw error;
    }
  },

  setLocalConsentData(data) {
    try {
      const consentData = this.createConsentData(data.preferences, data.timestamp);
      
      // Always save browser-level consent
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        preferences: consentData.preferences,
        timestamp: consentData.timestamp,
        version: CONSENT_VERSION
      }));

      // If logged in, also save user-specific consent
      if (this.userId) {
        const userStorageKey = getUserStorageKey(this.userId);
        localStorage.setItem(userStorageKey, JSON.stringify(consentData));
      }
    } catch (error) {
      console.error('Error saving to local storage:', error);
      throw error;
    }
  },

  cleanupOldConsentData() {
    try {
      // Get all localStorage keys
      const keys = Object.keys(localStorage);
      
      // Find and remove only user-specific consent data for other users
      keys.forEach(key => {
        if (key.startsWith('cookieConsent_') && key !== getUserStorageKey(this.userId)) {
          try {
            const data = JSON.parse(localStorage.getItem(key));
            // Verify it's actually consent data before removing
            if (data && data.preferences && data.version) {
              localStorage.removeItem(key);
              // console.log('Removed old user-specific consent data:', key);
            }
          } catch (e) {
            // If we can't parse the data, leave it alone
            console.warn('Failed to parse consent data:', key);
          }
        }
      });
    } catch (error) {
      console.warn('Error cleaning up old consent data:', error);
    }
  },

  async saveToAPI(data, retryCount = 3) {
    const consentData = this.createConsentData(data.preferences, data.timestamp);
    
    for (let i = 0; i < retryCount; i++) {
      try {
        await UserController.setUserSetting({
          value: JSON.stringify(consentData),
          setting: 'UserConsent'
        });
        return;
      } catch (error) {
        if (i === retryCount - 1) {
          throw error;
        }
        console.warn(`API save attempt ${i + 1} failed, retrying...`);
        await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
      }
    }
  },

  showCookieSettings() {
    // console.log('Opening cookie settings');
    this.showBanner = true;
    this.$nextTick(() => {
      this.showDetails = true;
    });
  },

  togglePreference(type) {
    if (type !== 'necessary') {
      this.preferences[type] = !this.preferences[type];
      // console.log(`${type} preference toggled:`, this.preferences[type]);
    }
  },

  async handleSaveAndExit() {
    if (this.showDetails) {
      await this.savePreferences();
    } else {
      this.showDetails = true;
    }
  },

  async savePreferences() {
    if (!navigator.onLine) {
      this.$root.$emit("snackbarError", "Cannot save preferences while offline");
      return;
    }

    try {
      this.isSaving = true;
      const consentData = this.createConsentData(this.preferences);

      // Save to both storages
      await Promise.all([
        this.setLocalConsentData(consentData),
        this.isLoggedIn ? this.saveToAPI(consentData) : Promise.resolve()
      ]);

      this.applyConsent(this.preferences);
      this.originalPreferences = { ...this.preferences };
      this.closeConsent();
      this.$root.$emit("snackbarSuccess", "Preferences saved successfully");
    } catch (error) {
      console.error('Save preferences error:', error);
      this.$root.$emit("snackbarError", "Failed to save preferences");
    } finally {
      this.isSaving = false;
    }
  },

  acceptAll() {
    this.preferences.analytics = true;
    this.savePreferences();
  },

  acceptNecessaryOnly() {
    this.preferences.analytics = false;
    this.savePreferences();
  },

  handleClose() {
    if (this.hasChanges) {
      if (confirm('You have unsaved changes. Are you sure you want to close?')) {
        this.preferences = { ...this.originalPreferences };
        this.closeConsent();
      }
    } else {
      this.closeConsent();
    }
  },

  closeConsent() {
    this.showBanner = false;
    this.showDetails = false;
  },

  resetState() {
    this.closeConsent();
    
    // Don't reset preferences if we have valid local consent data
    const localData = this.getLocalConsentData();
    if (localData && this.validateConsentData(localData).isValid) {
      // Keep existing preferences
      // console.log('Preserving existing consent preferences during logout');
      return;
    }

    // Only reset to defaults if no valid consent exists
    this.preferences = {
      necessary: true,
      analytics: false
    };
    this.originalPreferences = { ...this.preferences };
    this.initialized = false;
  },

  applyConsentData(data) {
    if (!data?.preferences) return;
    
    this.preferences = {
      necessary: true, // Always true
      analytics: !!data.preferences.analytics
    };
    this.originalPreferences = { ...this.preferences };
    this.applyConsent(this.preferences);
  },

  applyConsent(preferences) {
    this.removeNonEssentialCookies();
    if (preferences.analytics) {
      this.$analytics?.grantConsent();
    } else {
      this.$analytics?.revokeConsent();
    }
  },

  removeNonEssentialCookies() {
    document.cookie.split(';').forEach(cookie => {
      const name = cookie.split('=')[0].trim();
      if (!ESSENTIAL_COOKIES.includes(name)) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname};`;
      }
    });
  }
}
};
</script>

<style scoped>
.cookie-consent {
max-width: 600px !important;
z-index: 9999; /* Ensure it's above other elements */
}

.cookie-consent ::v-deep .v-snack__wrapper {
max-width: 100% !important;
width: 100% !important;
border-radius: 8px;
margin: 16px;
background: transparent !important;
box-shadow: none;
}

.consent-card {
background-color: #1E1E1E !important;
border-radius: 8px;
box-shadow: 0 0 18px 3px #0e0e0ec7 !important;
width: 100%;
overflow: hidden;
position: relative;
}

.cookie-section {
position: relative;
padding: 16px;
border-radius: 8px;
background: rgba(255, 255, 255, 0.05);
transition: background-color 0.2s ease;
}

.cookie-section.clickable {
cursor: pointer;
user-select: none;
}

.cookie-section.clickable:hover {
background: rgba(255, 255, 255, 0.07);
}

.cookie-section.clickable:active {
background: rgba(255, 255, 255, 0.09);
}

.cookie-section.clickable:focus-visible {
outline: 2px solid #fff;
outline-offset: 2px;
}

.cookie-consent ::v-deep .v-input--switch {
margin: 0;
padding: 0;
flex: 0 0 auto;
}

.cookie-consent ::v-deep .v-input--switch .v-input__slot {
margin: 0;
}

.cookie-consent ::v-deep .v-btn {
text-transform: none;
letter-spacing: 0.5px;
font-weight: 500;
min-width: 110px;
transition: opacity 0.2s ease;
}

.cookie-consent ::v-deep .v-btn:hover {
opacity: 0.9;
}

.cookie-consent ::v-deep .v-btn:focus-visible {
outline: 2px solid #fff;
outline-offset: 2px;
}

.v-chip.v-chip--x-small {
font-size: 0.625rem;
height: 20px;
}

a {
color: #fff !important;
opacity: 0.7;
transition: opacity 0.2s ease;
}

a:hover {
opacity: 1;
text-decoration: underline !important;
}

a:focus-visible {
outline: 2px solid #fff;
outline-offset: 2px;
}

.v-expand-transition-enter-active,
.v-expand-transition-leave-active {
transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
overflow: hidden;
}

.v-expand-transition-enter,
.v-expand-transition-leave-to {
opacity: 0;
transform: translateY(-8px);
}

.body-2 {
line-height: 1.5;
word-break: normal;
overflow-wrap: break-word;
}

@media (max-width: 600px) {
.cookie-consent {
  max-width: 100% !important;
}

.cookie-consent ::v-deep .v-snack__wrapper {
  margin: 0;
}

.consent-card {
  border-radius: 0;
}

.cookie-consent ::v-deep .v-btn {
  min-width: 0;
  padding: 0 12px;
}

.cookie-section {
  padding: 12px;
}

.v-card-actions {
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
}

.v-card-actions .v-btn {
  margin-bottom: 8px;
}
}

.consent-card {
animation: slide-up 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes slide-up {
from {
  transform: translateY(100%);
  opacity: 0;
}
to {
  transform: translateY(0);
  opacity: 1;
}
}

/* Accessibility improvements */
.cookie-consent :focus-visible {
outline: 2px solid #fff;
outline-offset: 2px;
}

/* High contrast mode improvements */
@media (forced-colors: active) {
.cookie-section {
  border: 1px solid CanvasText;
}

.cookie-consent ::v-deep .v-btn {
  border: 1px solid ButtonText;
}
}
</style>
